import { createRoot } from 'react-dom/client';
import '~/index.css';
import App from '~/App';

// Register hook to reload page if esbuild rebuilds the app
new EventSource('/esbuild').addEventListener('change', () => location.reload());

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Could not find element #root');
}

const root = createRoot(rootElement);
root.render(<App />);
